import styled from "styled-components";
import {
  Titulo3,
  Cuerpo,
  Subtitulo3,
  TituloSeccion,
  Leyenda,
  Subtitulo1,
} from "../../componentes-styled-compartidos/Textos";
import {
  EnvolvedorPantalla,
  Pantalla,
} from "../../componentes-styled-compartidos/Pantalla.styled";

const EncabezadoEvaluacion = styled(Subtitulo3)`
  align-items: center;
  background-color: var(--color-marca-normal);
  border-radius: 8px;
  border: solid 1px var(--fondo-gris);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: var(--texto-blanco);
  display: flex;
  padding: 10px 16px;
  width: 100%;
`;

const ImagenEncabezado = styled.img`
  border-radius: 31px;
  height: 62px;
  margin-right: 15px;
  object-fit: cover;
  width: 62px;
`;

const TituloEvaluacion = styled(Subtitulo3)`
  color: var(--color-gris-medio);
  margin-bottom: 20px;
  margin-top: 140px;
  opacity: 0.8;
  text-align: center;
`;

const EnvolvedorNumeroPreguntas = styled.div`
  display: flex;
  margin-top: 15px;
  width: 100%;
  justify-content: center;
`;

const ContenedorIconoPunto = styled.div`
  margin: 0 7px;
  color: ${(props) =>
    props.marcado ? "var(--fondo-verde-claro)" : "var(--fondo-gris)"};
`;

const CuerpoEvaluacion = styled(Subtitulo3)`
  color: var(--color-gris-claro);
  margin-top: 20px;
`;

const ContenedorExtrasEvaluacion = styled(Subtitulo3)`
  margin-bottom: 5px;
  margin-top: 5px;
  color: var(--color-azul-normal);
  width: 100%;
`;

const RenglonExtrasEvaluacion = styled.div`
  display: flex;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 230px;
`;

const RenglonExtrasEvaluacionCentrado = styled(RenglonExtrasEvaluacion)`
  justify-content: center;
`;

const ContenedorIconoExtrasEvaluacion = styled.div`
  margin-right: 12px;
`;

const ContenedorCampoTextoComentarios = styled.div`
  display: inherit;
`;

const TituloEvaluacionSubpantalla = styled(Titulo3)`
  display: flex;
  height: 54px;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 18px;
  text-align: left;
  width: 100%;
`;

const EnvolvedorEvaluacionSubpantalla = styled(EnvolvedorPantalla)`
  align-items: flex-start;
  min-height: 700px;
`;

const CuerpoEvaluacionSubpantalla = styled(Cuerpo)`
  color: var(--color-gris-medio);
  padding-bottom: 40px;
  width: 100%;
`;

const ContenedorReconocimientos = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Reconocimiento = styled(TituloSeccion)`
  align-items: center;
  color: var(--color-gris-medio);
  display: flex;
  flex-direction: column;
  font-family: var(--fuente-montserrat-bold);
  margin-top: 37px;
  text-transform: none;
`;

const ImagenReconocimiento = styled.img`
  height: 115px;
  width: 90px;
`;

const ContenedorPantallaEvaluacion = styled(Pantalla)`
  margin-top: 0px;
  .carousel li {
    height: 220px;
    padding: 0px !important;
  }
`;

const LigaComentario = styled(Leyenda)`
  display: flex;
  color: var(--color-azul-enlace);
  font-size: 16px;
  width: 100%;
  position: relative;
  svg {
    transform: scale(0.67);
    position: absolute;
    right: 0;
    top: -4px;
  }
`;
const Ordenar = styled.div`
  text-align: center;
  padding-block: 6%;
  padding-bottom: 6%;
`;

const ContenedorTextoComentario = styled.div`
  width: 100%;
  text-align: center;
`;

const NombredeEvaluado = styled(Subtitulo1)`
  align-items: center;
  color: var(--color-verde-normal);
  display: flex;
  flex-direction: column;
  font-family: var(--fuente-proxima-bold);
  margin-top: 40px;
  position: relative;
  text-align: center;
  width: 100%;
`;

const PopupContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;

  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.4);
  display: "block";
  font-size: 15px;
  left: 0;
  margin: 0 auto;
  overflow: auto;
  top: 0;
  z-index: 999;

  box-shadow: 0 0 10px rgba(55, 55, 55, 0.3);
  transition: 4000ms;
`;

const EvaluacionPopup = styled.div`
  background-color: var(--fondo-blanco-normal);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: fixed;
  bottom: 0px;
  min-height: 30%;
  width: 100%;
  padding: 20px 0px;
`;

const Titulo = styled(Titulo3)`
  margin-bottom: 15px;
  margin-top: 30px;
`;

const StylesButon = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "10px",
  },
  button: {
    flex: "1 1 45%",
    minWidth: "150px",
  },
};

export {
  EncabezadoEvaluacion,
  ImagenEncabezado,
  TituloEvaluacion,
  EnvolvedorNumeroPreguntas,
  ContenedorIconoPunto,
  CuerpoEvaluacion,
  ContenedorExtrasEvaluacion,
  RenglonExtrasEvaluacion,
  RenglonExtrasEvaluacionCentrado,
  ContenedorIconoExtrasEvaluacion,
  EnvolvedorEvaluacionSubpantalla,
  TituloEvaluacionSubpantalla,
  CuerpoEvaluacionSubpantalla,
  ContenedorCampoTextoComentarios,
  ContenedorReconocimientos,
  Reconocimiento,
  ImagenReconocimiento,
  ContenedorPantallaEvaluacion,
  ContenedorTextoComentario,
  LigaComentario,
  NombredeEvaluado,
  Ordenar,
  PopupContainer,
  EvaluacionPopup,
  Titulo,
  StylesButon,
};
