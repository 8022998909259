import styled from "styled-components";
import {
  Leyenda,
  BotonDescarga,
} from "../../componentes-styled-compartidos/Textos";

const BotonLesionado = styled(BotonDescarga)`
  font-family: var(--fuente-proxima-bold);
  color: var(--color-blanco-normal);
  background-color: ${(props) =>
    props.deshabilitado
      ? "var(--color-gris-claro)"
      : "var(--color-marca-normal)"};
  margin-bottom: 20px;
  margin-top: 3px;
  cursor: pointer;
  pointer-events: ${(props) => (props.deshabilitado ? "none" : "all")};
  gap: 4px;
`;

const ContenedorLesionado = styled.div`
  width: 100%;
`;

const EnvolvedorLesionados = styled.div`
  width: 100%;
`;

const RowNombreLesionado = styled.div`
  display: flex;
  align-items: center;
`;

const NombreLesionado = styled(Leyenda)`
  display: flex;
  align-items: center;
  color: var(--color-gris-medio);
  margin-bottom: 3px;
`;

const CheckLesionado = styled.input.attrs({ type: "checkbox" })`
  accent-color: var(--color-marca-normal);
  margin-right: 10px
  border-radius: 6px;
  width: 20px;
  height: 20px;
  margin-top: 0;
`;

export {
  BotonLesionado,
  ContenedorLesionado,
  EnvolvedorLesionados,
  RowNombreLesionado,
  NombreLesionado,
  CheckLesionado,
};
