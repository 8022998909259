import React from "react";
import PropTypes from "prop-types";
// import IconoEstrella from "@material-ui/icons/StarRounded";
import Slider from "@material-ui/core/Slider";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import {
  // ContenedorCalificacion,
  TituloEvaluacion,
  // EnvolvedorEvaluacion,
  ContenedorLinea,
} from "./Evaluacion.styled";

// Crear un tema personalizado
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#006729", // Color personalizado para primary (naranja)
    },
    secondary: {
      main: "#4caf50", // Color personalizado para secondary (verde)
    },
  },
});

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 10,
    label: "10",
  },
];

function valuetext(value) {
  return `${value}°C`;
}

const Calificacion = (props) => {
  const { texto, value, onSliderChange } = props;
  const handleChange = (event, newValue) => {
    // console.log("Valor del Slider:", newValue); // Muestra el valor en la consola
    onSliderChange(newValue); // Llama a la función pasada por el componente padre
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <TituloEvaluacion>{texto}</TituloEvaluacion>
        <ContenedorLinea>
          <Slider
            defaultValue={5}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-always"
            step={1}
            marks={marks}
            valueLabelDisplay="on"
            min={0}
            max={10}
            value={value} // Valor controlado por el estado del padre
            onChange={handleChange} // Llama a la función cuando cambia el valor
          />
        </ContenedorLinea>
      </ThemeProvider>
    </>
  );
};

Calificacion.propTypes = {
  texto: PropTypes.string,
  onSliderChange: PropTypes.func,
  value: PropTypes.number,
};
Calificacion.defaultProps = {
  texto: "",
  onSliderChange() {},
  value: 5,
};

export default Calificacion;
