import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  BotonLesionado,
  ContenedorLesionado,
  EnvolvedorLesionados,
  RowNombreLesionado,
  NombreLesionado,
  CheckLesionado,
} from "./Lesionados.styled";
import obtenerValorDeArregloDeStrings from "../../utilidades-compartidas/obtenerValorDeArregloDeStrings";
import useAccionesLog from "../../../utils/useAccionesLog";
import { ReactComponent as IconoDescargar } from "../../../recursos/iconos/ico_descargar.svg";

const Lesionados = ({ lesionados }) => {
  const diccionario = {
    botonLesionado: "Descargar",
  };
  const [lesionadosSeleccionados, setLesionadosSeleccionados] = React.useState(
    []
  );

  const estadoApp = useSelector((state) => state);

  const { runDownloadLog } = useAccionesLog(
    Object.keys(estadoApp.informacionContacto).includes("telefono")
      ? estadoApp.informacionContacto.telefono
      : ""
  );

  const lesionadosContenido = lesionados.map((lesionado) => ({
    nombre: obtenerValorDeArregloDeStrings(lesionado, "NombreLesionado: "),
    folio: obtenerValorDeArregloDeStrings(lesionado, "Folio: "),
    archivo: obtenerValorDeArregloDeStrings(lesionado, "Documento: "),
  }));

  const runAction = () => runDownloadLog(7);

  const addCheckLesionado = (checked, lesionado) => {
    if (checked) {
      setLesionadosSeleccionados([...lesionadosSeleccionados, lesionado]);
    }
    if (!checked) {
      setLesionadosSeleccionados(
        lesionadosSeleccionados.filter(
          (item) => item.archivo !== lesionado.archivo
        )
      );
    }
  };

  const handleDescargarLesionados = () => {
    lesionadosSeleccionados.forEach((lesionado) => {
      runAction();
      fetch(lesionado.archivo)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = `Pase_medico_${lesionado.folio}_${lesionado.nombre}.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        })
        .catch((error) =>
          // eslint-disable-next-line no-console
          console.error("Error al descargar el archivo:", error)
        );
    });
  };

  // TODO: Realizar pruebas unitarias
  return (
    <EnvolvedorLesionados>
      {lesionadosContenido.map((lesionado) => (
        <ContenedorLesionado key={lesionado.folio}>
          <RowNombreLesionado>
            <CheckLesionado
              type="checkbox"
              onChange={(e) => addCheckLesionado(e.target.checked, lesionado)}
              disabled={lesionado.archivo === ""}
            />
            <NombreLesionado>{lesionado.nombre}</NombreLesionado>
          </RowNombreLesionado>
        </ContenedorLesionado>
      ))}
      <BotonLesionado
        onClick={handleDescargarLesionados}
        deshabilitado={lesionadosSeleccionados.length === 0}
      >
        <IconoDescargar className="ico" width={12} height={12} />
        {diccionario.botonLesionado}
      </BotonLesionado>
    </EnvolvedorLesionados>
  );
};

Lesionados.propTypes = {
  lesionados: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

Lesionados.defaultProps = {
  lesionados: [],
};

export default Lesionados;
