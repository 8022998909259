const terminos = `<div id="text-block-2" class="mk-text-block">
<h1>TÉRMINOS Y CONDICIONES DE USO DE MEDIOS ELECTRÓNICOS</h1>
<h2>(hdi.com.mx y venta en línea autos)</h2>
<p><strong>Términos legales</strong></p>
<p>El uso de las Aplicaciones estará destinado exclusivamente a prospectos de clientes, titulares de pólizas y asegurados (en adelante Usuarios) de HDI SEGUROS, S.A. DE C.V., con domicilio Blvd. San Juan Bosco #5003, Colonia Rancho Seco, C.P. 37669, León, Guanajuato, México.. El acceso a las Aplicaciones es libre y voluntario y su utilización le da el carácter de Usuario. Para el uso de las Aplicaciones el Usuario debe aceptar estos Términos y Condiciones de Uso, por lo que se recomienda leerlos atentamente. Si no está de acuerdo con éstos, no debe continuar navegando ni accediendo a los contenidos de las mismas pues el hecho de acceder ellas confirma la aceptación de los presentes Términos y Condiciones de Uso.</p>
<p>Los presentes Términos y Condiciones de uso entre HDI Seguros y el Usuario rigen las relaciones a través de Medios Electrónicos: teléfono fijo y celular (móvil), las terminales de cómputo y la red mundial de comunicaciones conocida como internet.</p>
<p>Al registrarse en las Aplicaciones, usted podrá acceder a la información de todas las pólizas que tenga contratadas con HDI SEGUROS sin importar el canal por el que las haya adquirido.</p>
<p>Para contactar con HDI SEGUROS hágalo en <a href="http://www.hdi.com.mx">www.hdi.com.mx</a> o llamando a los teléfonos 01 800 0000 434.</p>
<p>La propiedad de la marca “HDI SEGUROS”, así como de las plataformas ‘’hdi.com.mx’’ y ‘’venta en línea autos’’ (en adelante las Aplicaciones) es exclusiva de HDI SEGUROS, por lo que los derechos de propiedad intelectual e industrial de estas Aplicaciones, así como gráficos, textos, planos, botones y, con carácter general, toda la información contenida en ellas pertenece a HDI SEGUROS. Queda prohibida la reproducción, carga, presentación, ejecución, transmisión, almacenamiento, traducción, adaptación, arreglo o distribución, ya sea total o parcial, realizadas sin el consentimiento y autorización expresa de HDI SEGUROS. La contravención a lo anterior constituye una infracción de los derechos de propiedad intelectual e industrial. HDI SEGUROS no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad industrial e intelectual o sobre cualquier otra propiedad o derecho relacionado con las Aplicaciones.</p>
<p>&nbsp;</p>
<p>Condiciones generales del servicio:</p>
<p><strong>1.- OBJETO</strong></p>
<p>A través de los presentes Términos y Condiciones de Uso se rigen las Operaciones y los Servicios que HDI SEGUROS pone a disposición de los Usuarios que hacen uso de los Medios Electrónicos, entre otros información de sus productos, beneficios y servicios; contratación de productos, modificación de&nbsp; productos contratados, pago de primas, red de prestadores de servicios y en general las operaciones necesarias para la gestión de los productos y prestación de servicios adicionales vinculados a las coberturas, asistencias o beneficios de productos contratados con HDI SEGUROS.</p>
<p>Los productos de seguros comercializados por HDI SEGUROS se rigen por las Condiciones Generales aplicables a cada producto o servicio y registradas ante las autoridades competentes, las cuales se podrán consultar en el sitio <a href="https://www.hdi.com.mx/condiciones-generales/">https://www.hdi.com.mx/condiciones-generales/</a>&nbsp; sección Condiciones Generales.</p>
<p>HDI como Responsable podrá remitir Datos personales a:</p>
<p>– Sociedades relacionadas con la finalidad de ofrecer al Titular otros productos y servicios, siempre y cuando no exista prohibición por parte del Titular”</p>
<p><strong>2.- EL USUARIO</strong></p>
<p>El uso seguro de las Aplicaciones requiere identificar al Usuario a través de datos únicos que lo vinculan con HDI SEGUROS, tal como número de póliza. Adicionalmente se requiere, en función del tipo de operación o servicio implicado, medidas de autentificación de la identidad del Usuario, entre ellas cuestionarios, contraseñas de acceso (password), o mecanismos de generación de contraseñas dinámicas (token). Estos medios de identificación y autentificación serán utilizados en sustitución de la firma autógrafa y producirán los mismos efectos que las leyes otorgan a los documentos correspondientes; por lo que las operaciones y servicios realizados a través de las Aplicaciones con uso de las medidas de identificación y procesos de autentificación de identidad tendrán el mismo valor que se concede a los documentos con firma autógrafa.</p>
<p>El Usuario deberá identificarse con HDI SEGUROS usando información que lo individualice, nombre de Usuario y contraseña.</p>
<p>Para efectos de registro el Usuario deberá indicar su correo electrónico que será su nombre de Usuario y generar su contraseña, con los cuales accederá en las ocasiones subsecuentes. No obstante, HDI SEGUROS también podrá asignar contraseña de acceso al Usuario, en caso de que éste lo requiera.</p>
<p>El uso del nombre de Usuario y de la contraseña será exclusiva responsabilidad del Usuario, quien reconoce y acepta que son de carácter personal, intransferible y confidencial y acepta desde ahora como suyas todas las operaciones que se celebren con HDI SEGUROS utilizando las mismas, y para todos los efectos legales a que haya lugar.</p>
<p>Durante su registro en el uso de los Medios Electrónicos el Usuario deberá proporcionar una dirección de correo electrónica y sólo en su defecto un número telefónico celular o móvil que será el medio de contacto a través del cual HDI SEGUROS le haga llegar las notificaciones o comprobantes, en su caso, de las Operaciones y Servicios realizados.</p>
<div>
<p>El usuario se dará de baja en caso de estar inactivo durante 6 meses, serán borrados sus datos del sistema. Asimismo, en caso de que el Usuario deje de ser cliente de HDI SEGUROS, los datos de la cuenta permanecerán en el servidor 6 meses después de su cancelación como cliente y posterior a ese plazo, serán borrados del sistema. No obstante, en caso de que el Usuario desee que los datos de su cuenta sean eliminados por HDI SEGUROS antes de dicho plazo, podrá presentar una solicitud contactando a HDI SEGUROS, a través de los medios como el <strong>portal del cotizador de seguros autos</strong> y dicha solicitud será atendida en el plazo no mayor de cinco días, por último el usuario también podrá darse de baja por sí mismo una vez que haya completado su compra a través del canal digital, recibirá un correo donde podrá eliminar su cuenta cuando lo deseé.</p>
</div>
<p>&nbsp;</p>
<p>Los servicios de localización, en su caso, de las Aplicaciones permiten que éstas recopilen y usen información basada en su ubicación actual, para poder ofrecer los servicios que presta HDI SEGUROS directamente o a través de terceros, los cuales se darán basados en su&nbsp;ubicación. Por ejemplo venta en línea HDI SEGUROS pueden usar sus datos de ubicación o sus búsquedas de ubicación para ayudarle a encontrar un taller, una cristalera, un autopronto, un hospital, médico 24 horas, programación de ambulancia o para que nuestro ajustador pueda localizarlo en caso de que tenga un siniestro, en general para que HDI SEGUROS pueda brindarle asistencia vial, de hogar y salud. Para tener acceso a estas funciones, debe activar los servicios de localización en su dispositivo electrónico.</p>
<p>Al activar los servicios de localización en su dispositivo electrónico,&nbsp;acepta y autoriza a HDI SEGUROS, subsidiarias, filiales, empresas relacionadas&nbsp;y a aquellos a los que HDI SEGUROS haya dado permiso para transmitir, recopilar, mantener, procesar y usar sus datos de ubicación y sus búsquedas de ubicación para las finalidades antes mencionadas.</p>
<p>Descargar cualquier app de HDI SEGURO en su teléfono móvil es gratis. HDI SEGUROS no se hace cargo de los gastos que generen el servicio de telecomunicaciones tal como el costo por el consumo de datos móviles, programas, software y hardware ni recomienda o promueve marca alguna de insumos tecnológicos.</p>
<p><strong>3.- Compromiso del Usuario</strong></p>
<p>El Usuario se compromete a utilizar las Aplicaciones de buena fe, de forma diligente, lícita y adecuada, sin incurrir en actividades que puedan ser consideradas ilícitas o ilegales que infrinjan los derechos de HDI SEGUROS, no haciendo uso indebido o ilícito de la información ni de los contenidos existentes en las Aplicaciones.</p>
<p>Asimismo, el Usuario se compromete a no llevar a cabo de manera enunciativa, más no limitativa, alguna de las siguientes conductas:</p>
<ol>
<li>Reproducir, copiar, distribuir cualquier contenido de las Aplicaciones para cualquier fin sin autorización de HDI SEGUROS.</li>
<li>Transformar o modificar los Contenidos de las Aplicaciones.</li>
<li>Suprimir, eludir o manipular demás datos identificativos de los derechos de HDI SEGUROS incorporados a los Contenidos de las Aplicaciones.</li>
<li>Destruir, alterar, inutilizar o, de cualquier otra forma, dañar los datos, programas o documentos electrónicos del prestador de servicios. Obstaculizar el acceso de otros Usuarios a Internet, así como realizar acciones que dañen, interrumpan o generen errores en el sistema de acceso.</li>
<li>Introducir programas, virus o cualquier otro dispositivo que cause o sea susceptible de causar cualquier tipo de alteración o perjuicio en los sistemas informáticos de HDI SEGUROS o de terceros.</li>
<li>Cualquier ataque o intento de alteración o manipulación al sistema informático o a las Aplicaciones.</li>
</ol>
<p>Es obligación del Usuario disponer de herramientas adecuadas para la detección y, en su caso supresión, de virus y cualquier otro elemento informático dañino de su dispositivo electrónico.</p>
<p>Es responsabilidad del Usuario la veracidad de los datos que proporcione, a través de las Aplicaciones. Los documentos e informaciones que el Usuario proporcione a o hacia HDI SEGUROS, directamente, de terceros que haya autorizado o a través de prestadores de servicios con motivo de las Operaciones y Servicios utilizando los Medios Electrónicos tendrán el mismo efecto y valor que los documentos e informaciones presentados o proporcionados de manera física e impresa; por lo que el Usuario acepta su responsabilidad por la autenticidad de los documentos y veracidad de la información contenida en ellos y el resto de las informaciones proporcionadas por estos Medios para, entre otros, la contratación de productos, reporte y gestión de siniestros, así como, en general, la realización de las Operaciones y Servicios por estas Aplicaciones.</p>
<p>El Usuario acepta toda la responsabilidad de sus acciones y las consecuencias de las mismas.</p>
<ol>
<li><strong>Condiciones y restricciones del uso de los portales electrónicos </strong></li>
</ol>
<p>En la realización de Operaciones y con motivo de la prestación de los Servicios Adicionales a través del uso de los Medios Electrónicos, HDI SEGUROS proporcionará al Usuario, como evidencia de realización de la Operación y seguimiento de los Servicios evidencias como número de póliza contratada, folio consecutivo de número de atención o número de siniestro asignado.</p>
<p>En adición HDI SEGUROS enviará a la dirección de correo electrónico o número telefónico proporcionado por el Usuario al registrarse como Usuario de los Medios Electrónicos, el comprobante de la Operación o Servicio realizado, tales como carátula de póliza, recibo de primas o endosos.</p>
<p>(Mecanismo de cancelación, proceso, el canal, tiempo de atención y adicional mencionar cuál es el proceso de autenticación.)</p>
<p>En razón de las medidas de seguridad electrónica y operativa entre el Usuario y HDI SEGUROS, algunas de las Operaciones y Servicios podrán limitarse en el Uso de Medios Electrónicos, en cuyo caso el interesado deberá presentar su solicitud de trámite o servicio a través del agente de seguros o personal autorizado por HDI SEGUROS en sus diferentes oficinas de servicio.</p>
<p>HDI SEGUROS se reserva el derecho a modificar cualquier contenido y demás elementos o funcionalidades incluidas en las Aplicaciones en cualquier momento y sin previo aviso.</p>
<p>Si el Usuario no lleva a cabo las actualizaciones recomendadas trabajará bajo su propio riesgo con una versión no actualizada de las Aplicaciones y puede llegar el momento en que ésta deje de funcionar por completo debido a que la versión instalada en el dispositivo del Usuario no sea la actualizada.</p>
<p>HDI SEGUROS no cubrirá el gasto que implique al Usuario obtener dichas actualizaciones (paquete de datos o el uso de internet) ya que esos cargos corren a cargo del Usuario con su proveedor de datos y/o de telefonía móvil.</p>
<p>HDI SEGUROS no controla ni garantiza la ausencia de virus ni de otros elementos contenidos en los dispositivos electrónicos del Usuario, que puedan producir alteraciones en su equipo o en sus archivos y documentos electrónicos almacenados.</p>
<p>HDI SEGUROS no es responsable de las consecuencias eventuales que puedan afectar la cuenta del Usuario en las Aplicaciones como resultado de un caso fortuito o de fuerza mayor.</p>
<p>HDI SEGUROS realiza sus mejores esfuerzos para mantener la continuidad y disponibilidad de los contenidos y servicios en las Aplicaciones y sitios web; sin embargo, no garantiza la disponibilidad y continuidad del funcionamiento de éstas, toda vez que ocasionalmente éstas podrán no estar disponibles temporalmente por motivos de mantenimiento o por otras causas. Cuando ello sea razonablemente posible, HDI SEGUROS advertirá de las interrupciones en el funcionamiento de las Aplicaciones.</p>
<p>HDI SEGUROS no asume ningún tipo de responsabilidad por errores, interrupciones, retrasos de funcionamiento o de transmisión, fallos de la línea de comunicaciones o alteración de las comunicaciones, derivado del funcionamiento de los dispositivos propiedad o en posesión del Usuario. Tampoco es responsable por causas que dependen de prestadores de servicios externos tales como proveedores de servicios de energía eléctrica, de internet o de telecomunicaciones</p>
<p>HDI SEGUROS no se responsabiliza de daños surgidos por la disfunción o una configuración incorrecta del entorno telefónico, informático o de software del acceso a internet.</p>
<p><strong>5.- RESPONSABILIDAD POR LOS ENLACES CONTENIDOS EN LA RED</strong></p>
<p>Las Aplicaciones puede poner a disposición de los Usuarios enlaces tales como links, banners, botones, directorios y herramientas de búsqueda que les permiten acceder a sitios web pertenecientes a y/o gestionados por terceros (en adelante, Sitios Enlazados), cuyo objeto es facilitar a los Usuarios la búsqueda y acceso a la información.</p>
<p>HDI SEGUROS no será responsable de la información que dirija al Usuario a través de los Sitios Enlazados que pudieran existir, siempre que no tenga conocimiento efectivo de que la actividad o la información a la que remiten o recomiendan dichos Sitios Enlazados es ilícita o de que lesiona bienes o derechos de un tercero susceptibles de indemnización, o si lo tiene, actúe con diligencia para suprimir o inutilizar el enlace correspondiente.</p>
<p>Los hipervínculos (links) a sitios de HDI SEGUROS, y a terceras partes dependen de cada sitio enlazado y los contenidos de dichas páginas son responsabilidad de la entidad titular del mismo. HDI SEGUROS no se hace responsable de los contenidos de los sitios enlazados, ni garantiza que dichos sitios estén operativos en todo momento puesto que no realiza comprobaciones periódicas sobre el estado de los mismos.</p>
<p>HDI SEGUROS no puede garantizar que los enlaces a otros lugares sean exactos en el momento de su acceso no pudiendo exigirse obligación alguna de actualización de los mismos a HDI SEGUROS.</p>
<p>HDI SEGUROS no garantiza ni asume ningún tipo de responsabilidad por los daños y perjuicios de toda clase que puedan deberse a (a) el funcionamiento, disponibilidad, accesibilidad o continuidad de los Sitios Enlazados; (b) el mantenimiento de los servicios, contenidos, información, datos, archivos, productos y cualquier clase de material existente en los Sitios Enlazados; (c) la prestación o transmisión de los servicios, contenidos, información, datos, archivos, productos y cualquier clase de material existente en los Sitios Enlazados; (d) la calidad, licitud, fiabilidad y utilidad de los servicios, contenidos, información, datos, archivos, productos y cualquier clase de material existente en los Sitios Enlazados.</p>
<p>El Usuario, por tanto, debe extremar la prudencia en la valoración y utilización de los servicios, información, datos, archivos, productos y cualquier clase de material existente en los Sitios Enlazados.</p>
<p><strong>6.- RESPONSABILIDAD POR DAÑOS Y PERJUICIOS</strong></p>
<p>Cuando el Usuario realice cualquier intento o ejecución de aplicación de programas o sistemas que con intención, accidente o error pretendan atacar o vulnerar o bien ataquen o vulneren las Aplicaciones,&nbsp;podrá ser perseguido con las acciones legales pertinentes que a criterio de HDI SEGUROS sean suficientes, incluyendo penales, civiles y de cualquier otra naturaleza.</p>
<p>El uso de las Aplicaciones y portales web de modo distinto al específicamente autorizado en los presentes Términos y Condiciones, sin el previo consentimiento por escrito de HDI SEGUROS, queda estrictamente prohibido y podrá constituir una infracción de la legislación aplicable, incluyendo las leyes de propiedad industrial y de derechos de autor.</p>
<p>El Usuario responderá por los daños y perjuicios que pudiera ocasionar a HDI SEGUROS por el incumplimiento e inobservancia de los presentes Términos y Condiciones.</p>
<p>HDI SEGUROS se reserva el derecho de denegar o retirar el acceso a las Aplicaciones en cualquier momento a aquellos Usuarios que incumplan con los presentes Términos y Condiciones.</p>
<p><strong>7.- UTILIZACIÓN DE COOKIES</strong></p>
<p>Las cookies son archivos de texto que contienen pequeñas cantidades de información y que se descargan en los dispositivos electrónicos del Usuario cuando visite las Aplicaciones. Después, las cookies, se envían de vuelta a las Aplicaciones de origen en cada una de las visitas subsiguientes (cookies de origen) o a otra página web que reconozca esa cookie (cookies de terceros). Las cookies son útiles porque hacen posible que una página web reconozca el dispositivo de un Usuario. Las cookies tienen muchas finalidades, como permitirte navegar entre páginas con eficiencia, recordar tus preferencias y, en general, mejorar tu experiencia como usuario.</p>
<p>Las cookies no pueden ejecutar ningún programa o virus. Las cookies de HDI SEGUROS no pueden leer datos de su disco duro.</p>
<p>Las Aplicaciones usan cookies con la intención de conocer el sistema operativo que utiliza, el navegador (Internet Explorer, Netscape, Opera, etc.), los sitios y los servicios a los que se accede y el tiempo que permanece en ellos, el proveedor de acceso a Internet y la ubicación geográfica en que se encuentra.</p>
<p>Es imprescindible que consulte las opciones de internet de su navegador para configurar el funcionamiento y control de las cookies.</p>
<p>HDI SEGUROS reúne información acerca de la navegación de los Usuarios cuando estos navegan por Las Aplicaciones. En ningún caso la dirección IP de su ordenador será almacenada ni guardada (la dirección IP es un número que identifica a un dispositivo).</p>
<p><strong>8- AVISO DE PRIVACIDAD</strong></p>
<p>HDI Seguros llevará a cabo el tratamiento de sus datos personales de acuerdo con las finalidades previstas en su aviso de privacidad, el cual puede consultar en <a href="https://www.hdi.com.mx/politicas-de-privacidad/">https://www.hdi.com.mx/ </a>&nbsp;&nbsp;en la sección Aviso de Privacidad.</p>
<p><strong>9.- LEY APLICABLE Y JURISDICCIÓN COMPETENTE</strong></p>
<p>Para cualquier controversia o cuestión que pudiera derivarse de las Aplicaciones, ejecución e interpretación de los presentes Términos y Condiciones, el Usuario y HDI SEGUROS aplicarán los principios de la buena fe y se regirán por las leyes vigentes en los Estados Unidos Mexicanos. En caso de conflicto, el Usuario y HDI SEGUROS se someten expresamente a la jurisdicción y competencia de los tribunales del fuero común en la Ciudad de León, Guanajuato, renunciando a cualquier otro fuero que en razón de sus domicilios presentes o futuros o por cualquier otra causa, pudiera corresponderles.</p>
<p>El presente texto se pone a disposición de los Usuarios en <a href="http://www.hdi.com.mx/politicademedioselectronicos">www.hdi.com.mx/politicademedioselectronicos</a> &nbsp;para ser consultado en cualquier momento.</p>

<div class="clearboth"></div>
</div>`;
export default terminos;
